const wearableAssetTypes = [
  {
    maxNumber: 1,
    id: 18,
    name: 'Face',
    type: 'Face',
    catalogNameKey: 'Label.Face'
  },
  {
    maxNumber: 1,
    id: 19,
    name: 'Gear',
    type: 'Gear',
    catalogNameKey: 'Label.Gear'
  },
  {
    maxNumber: 1,
    id: 17,
    name: 'Head',
    type: 'Head',
    assetTypesToUnequip: [76, 77, 78, 79],
    body: true,
    catalogNameKey: 'Label.AccessoryHead'
  },
  {
    maxNumber: 1,
    id: 29,
    name: 'Left Arm',
    type: 'LeftArm',
    body: true,
    catalogNameKey: 'Label.LeftArm'
  },
  {
    maxNumber: 1,
    id: 30,
    name: 'Left Leg',
    type: 'LeftLeg',
    body: true,
    catalogNameKey: 'Label.LeftLeg'
  },
  {
    maxNumber: 1,
    id: 12,
    name: 'Pants',
    type: 'Pants',
    catalogNameKey: 'Label.Clothing.ClassicPants'
  },
  {
    maxNumber: 1,
    id: 28,
    name: 'Right Arm',
    type: 'RightArm',
    body: true,
    catalogNameKey: 'Label.RightArm'
  },
  {
    maxNumber: 1,
    id: 31,
    name: 'Right Leg',
    type: 'RightLeg',
    body: true,
    catalogNameKey: 'Label.RightLeg'
  },
  {
    maxNumber: 1,
    id: 11,
    name: 'Shirt',
    type: 'Shirt',
    catalogNameKey: 'Label.ClassicShirt'
  },
  {
    maxNumber: 1,
    id: 2,
    name: 'T-Shirt',
    type: 'TShirt',
    catalogNameKey: 'Label.ClassicTShirt'
  },
  {
    maxNumber: 1,
    id: 27,
    name: 'Torso',
    type: 'Torso',
    body: true,
    catalogNameKey: 'Label.Torso'
  },
  {
    maxNumber: 1,
    id: 48,
    name: 'Climb Animation',
    type: 'ClimbAnimation',
    catalogNameKey: 'Label.ClimbAnimation'
  },
  {
    maxNumber: 1,
    id: 49,
    name: 'Death Animation',
    type: 'DeathAnimation',
    catalogNameKey: 'Label.DeathAnimation'
  },
  {
    maxNumber: 1,
    id: 50,
    name: 'Fall Animation',
    type: 'FallAnimation',
    catalogNameKey: 'Label.FallAnimation'
  },
  {
    maxNumber: 1,
    id: 51,
    name: 'Idle Animation',
    type: 'IdleAnimation',
    catalogNameKey: 'Label.IdleAnimation'
  },
  {
    maxNumber: 1,
    id: 52,
    name: 'Jump Animation',
    type: 'JumpAnimation',
    catalogNameKey: 'Label.JumpAnimation'
  },
  {
    maxNumber: 1,
    id: 53,
    name: 'Run Animation',
    type: 'RunAnimation',
    catalogNameKey: 'Label.RunAnimation'
  },
  {
    maxNumber: 1,
    id: 54,
    name: 'Swim Animation',
    type: 'SwimAnimation',
    catalogNameKey: 'Label.SwimAnimation'
  },
  {
    maxNumber: 1,
    id: 55,
    name: 'Walk Animation',
    type: 'WalkAnimation',
    catalogNameKey: 'Label.WalkAnimation'
  },
  {
    maxNumber: 1,
    id: 56,
    name: 'Pose Animation',
    type: 'PoseAnimation',
    catalogNameKey: 'Label.PoseAnimation'
  },
  {
    maxNumber: 0,
    id: 61,
    name: 'Emote Animation',
    type: 'EmoteAnimation',
    catalogNameKey: 'Label.Emote'
  },
  {
    maxNumber: 3,
    id: 8,
    name: 'Hat',
    type: 'Hat',
    catalogNameKey: 'Label.Hat'
  },
  {
    maxNumber: 1,
    id: 41,
    name: 'Hair Accessory',
    type: 'HairAccessory',
    catalogNameKey: 'Label.HairAccessory'
  },
  {
    maxNumber: 1,
    id: 42,
    name: 'Face Accessory',
    type: 'FaceAccessory',
    catalogNameKey: 'Label.FaceAccessory'
  },
  {
    maxNumber: 1,
    id: 43,
    name: 'Neck Accessory',
    type: 'NeckAccessory',
    catalogNameKey: 'Label.NeckAccessory'
  },
  {
    maxNumber: 1,
    id: 44,
    name: 'Shoulder Accessory',
    type: 'ShoulderAccessory',
    catalogNameKey: 'Label.ShoulderAccessory'
  },
  {
    maxNumber: 1,
    id: 45,
    name: 'Front Accessory',
    type: 'FrontAccessory',
    catalogNameKey: 'Label.FrontAccessory'
  },
  {
    maxNumber: 1,
    id: 46,
    name: 'Back Accessory',
    type: 'BackAccessory',
    catalogNameKey: 'Label.BackAccessory'
  },
  {
    maxNumber: 1,
    id: 47,
    name: 'Waist Accessory',
    type: 'WaistAccessory',
    catalogNameKey: 'Label.WaistAccessory'
  },
  {
    maxNumber: 1,
    id: 72,
    name: 'Dress Skirt Accessory',
    type: 'DressSkirtAccessory',
    catalogNameKey: 'Label.Skirt'
  },
  {
    maxNumber: 1,
    id: 67,
    name: 'Jacket Accessory',
    type: 'JacketAccessory',
    catalogNameKey: 'Label.Jacket'
  },
  {
    maxNumber: 1,
    id: 70,
    name: 'Left Shoe Accessory',
    type: 'LeftShoeAccessory',
    catalogNameKey: 'Label.LeftShoe'
  },
  {
    maxNumber: 1,
    id: 71,
    name: 'Right Shoe Accessory',
    type: 'RightShoeAccessory',
    catalogNameKey: 'Label.RightShoe'
  },
  {
    maxNumber: 1,
    id: 66,
    name: 'Pants Accessory',
    type: 'PantsAccessory',
    catalogNameKey: 'Label.Clothing.PantsAccessories'
  },
  {
    maxNumber: 1,
    id: 65,
    name: 'Shirt Accessory',
    type: 'ShirtAccessory',
    catalogNameKey: 'Label.Shirt'
  },
  {
    maxNumber: 1,
    id: 69,
    name: 'Shorts Accessory',
    type: 'ShortsAccessory',
    catalogNameKey: 'Label.Clothing.ShortsAccessories'
  },
  {
    maxNumber: 1,
    id: 68,
    name: 'Sweater Accessory',
    type: 'SweaterAccessory',
    catalogNameKey: 'Label.Sweater'
  },
  {
    maxNumber: 1,
    id: 64,
    name: 'T-Shirt Accessory',
    type: 'TShirtAccessory',
    catalogNameKey: 'Label.TShirt'
  },
  {
    maxNumber: 1,
    id: 76,
    name: 'Eyebrow Accessory',
    type: 'EyebrowAccessory',
    catalogNameKey: 'Label.Eyebrow'
  },
  {
    maxNumber: 1,
    id: 77,
    name: 'Eyelash Accessory',
    type: 'EyelashAccessory',
    catalogNameKey: 'Label.Eyelash'
  },
  {
    maxNumber: 1,
    id: 78,
    name: 'Mood Animation',
    type: 'MoodAnimation',
    catalogNameKey: 'Label.MoodAnimation'
  },
  {
    maxNumber: 1,
    id: 79,
    name: 'Dynamic Head',
    type: 'DynamicHead',
    assetTypesToUnequip: [17, 76, 77, 78, 79],
    body: true,
    catalogNameKey: 'Label.DynamicHead'
  }
];

const maxAccessories = 10;
const maxLayeredClothing = 5;

const avatarAssetTypeNames = {
  hat: 'Hat',
  hairAccessory: 'HairAccessory',
  faceAccessory: 'FaceAccessory',
  neckAccessory: 'NeckAccessory',
  shoulderAccessory: 'ShoulderAccessory',
  frontAccessory: 'FrontAccessory',
  backAccessory: 'BackAccessory',
  waistAccessory: 'WaistAccessory',
  climbAnimation: 'ClimbAnimation',
  fallAnimation: 'FallAnimation',
  jumpAnimation: 'JumpAnimation',
  runAnimation: 'RunAnimation',
  swimAnimation: 'SwimAnimation',
  walkAnimation: 'WalkAnimation',
  emoteAnimation: 'EmoteAnimation',
  idleAnimation: 'IdleAnimation',
  dynamicHead: 'DynamicHead',
  hairAccessoryName: 'Hair Accessory',
  faceAccessoryName: 'Face Accessory',
  neckAccessoryName: 'Neck Accessory',
  shoulderAccessoryName: 'Shoulder Accessory',
  frontAccessoryName: 'Front Accessory',
  backAccessoryName: 'Back Accessory',
  waistAccessoryName: 'Waist Accessory',
  climbAnimationName: 'Climb Animation',
  fallAnimationName: 'Fall Animation',
  jumpAnimationName: 'Jump Animation',
  runAnimationName: 'Run Animation',
  swimAnimationName: 'Swim Animation',
  walkAnimationName: 'Walk Animation',
  emoteAnimationName: 'Emote Animation',
  idleAnimationName: 'Idle Animation',
  dynamicHeadName: 'Dynamic Head'
};

// This is less than ideal, but might be changed later when we split these asset types into their own categories
// If not, this should eventually be moved into avatar-rules or metadata
const layeredClothingGroups = {
  64: 'Tops', // T Shirt Accessory
  65: 'Tops', // Shirt Accessory
  66: 'Bottoms', // Pants Accessory
  69: 'Bottoms', // Shorts Accessory
  72: 'Bottoms', // Dress Skirt Accessory
  67: 'Outerwear', // Jacket Accessory
  68: 'Tops' // Sweater Accessory
};

const layeredClothingGroupLimits = {
  Tops: 1,
  Bottoms: 1,
  Outerwear: 1
};

const layeredClothingAssetOrder = {
  76: 1, // Eyebrow Accessory
  77: 2, // Eyelash Accessory
  70: 3, // Left Shoe
  71: 3, // Right Shoe
  66: 4, // Layered Pants (i.e. Pants Accessory)
  69: 5, // Shorts Accessory
  72: 6, // Dress Skirt Accessory
  64: 7, // Layered TShirt (i.e. TShirt Accessory)
  65: 8, // Layered Shirt (i.e. Shirt Accessory)
  68: 9, // Sweater Accessory
  67: 10, // Jacket Accessory
  41: 11 // Hair (i.e. Hair Accessory)
};

const advancedEditorLimits = {
  41: 2 // Hair
};

const dnaLayeredAccessories = [41, 76, 77];

export {
  wearableAssetTypes,
  maxAccessories,
  maxLayeredClothing,
  avatarAssetTypeNames,
  layeredClothingGroups,
  layeredClothingGroupLimits,
  layeredClothingAssetOrder,
  advancedEditorLimits,
  dnaLayeredAccessories
};
