import { ready } from 'core-utilities';
import React from 'react';
import { render } from 'react-dom';
import '../../../css/favoritesButton/favorites-button.scss';
import App from './App';

const getAssetId = (containerElement: HTMLElement) => {
  return parseInt(containerElement.getAttribute('data-asset-id'), 10);
};

const getAssetType = (containerElement: HTMLElement) => {
  // Convert 'Asset' to 'assets', 'Bundle' to 'bundles' for endpoint handling
  let itemType = containerElement.getAttribute('data-item-type');
  itemType += 's';
  return itemType.toLocaleLowerCase();
};

const mountReactApp = (containerElement: HTMLElement) => {
  render(
    <App assetId={getAssetId(containerElement)} itemType={getAssetType(containerElement)} />,
    containerElement
  );
};

ready(() => {
  const containerElement = document.getElementById('favorites-button');

  if (containerElement) {
    // If the element is already in the DOM, mount the React app
    mountReactApp(containerElement);
  } else {
    // If the element is added dynamically, use MutationObserver
    const observer = new MutationObserver(() => {
      const dynamicContainer = document.getElementById('favorites-button');
      if (dynamicContainer) {
        mountReactApp(dynamicContainer);
        observer.disconnect(); // Stop observing after the element is found
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });
  }
});
